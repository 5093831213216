import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import InfiniteScroll from "react-infinite-scroll-component";
import './index.css';


export function Lista() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({word: "", uf_codigo: "", cidade_codigo: "", ativos: ""});
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const searchRef = useRef({});
    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [input, setInput] = useState({});
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);

    useEffect(() => {
        if (user.token !== "") { 
            _userRef.current = user;  
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);

    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(async()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    await LoadEstados();
                }
            }, 100);
        }
    }
    //#endregion Init


    //#region Loaders
    const LoadEstados = async() => {
        window.w_loadObj("#uf_codigo");

        await Promise.resolve(new Request().Run("/json/estados.json", "GET", {}, user.token, 3600*1000*24))
            .then((data) => {
                setEstados(data);
                window.w_unloadObj("#uf_codigo");
            }).catch(() => {
                window.w_unloadObj("#uf_codigo");
            });
        
        _HandlerSearchChange(null);
    }


    const LoadCidades = async(uf_codigo) => {
        window.w_loadObj("#cidade_codigo")

        Promise.resolve(new Request().Run("api/Cadastros/Cidades?uf_codigo="+uf_codigo, "GET", {}, user.token, 3600*1000*24))
            .then((data) => {
                if(data) {
                    setCidades(data); 
                    //searchRef.current.cidade_codigo = data[0].id;
                    setSearch(searchRef.current);              
                }
                window.w_unloadObj("#cidade_codigo");
            }).catch(() => {
                window.w_unloadObj("#cidade_codigo");
            });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleInputChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});

        let _search = { ...search};
        _search[e.currentTarget.id] = e.currentTarget.value;
        searchRef.current = _search;
        setSearch(_search);

        if(e.currentTarget.id === "uf_codigo") {
            LoadCidades(e.currentTarget.value); 
        } 
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Orcamentos/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", search, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                listRef.current = listRef.current.concat(data.list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Orcamentos/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", search, user.token))
                .then((data) => {
                    listRef.current = listRef.current.concat(data.list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UnidadeClick = (item) => {
       
    }


    const _DisableUnidade = async(item) => {
       
    }
    //#endregion Handlers



    return (
        <>
             <div className="row OrcamentosLista">
                <div className="col-lg-12">
                    <div className="ibox">



                        <div className="ibox-content">

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="text" id="word" name="word" placeholder="Busca por nome" value={search.word} className="form-control" onChange={_HandleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select id="uf_codigo" name="uf_codigo" onChange={_HandleInputChange} defaultValue={''} value={search.uf_codigo} className="form-control">
                                        <option value="">Todos Estados</option>
                                        {estados.map((option, i) => (
                                            <option key={'opt_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select id="cidade_codigo" name="cidade_codigo" onChange={_HandleInputChange} disabled={search.uf_codigo===""} defaultValue={''} value={search.cidade_codigo}  className="form-control">
                                        <option value="">Todas Cidades</option>
                                        {cidades.map((option, i) => (
                                            <option key={'city_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                {/*<div className="col-sm-2">
                                    <div className="form-group">
                                        <select id="ativos" name="ativos" onChange={_HandleInputChange} defaultValue={''} value={search.ativos} className="form-control">
                                        <option value="">Todas assinaturas</option>
                                        <option value="0">Assinatura ativa</option>
                                        <option value="1">Assinatura inativa</option>
                                        </select>
                                    </div>
                                </div>*/}

                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <button className="btn btn-white btn-sm" type="button"  style={{ height:'35px', width:'35px' }} onClick={_HandlerSearchChange}><i className={'fa fa-search'}></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <InfiniteScroll
                                        dataLength={list.length}
                                        next={_fetchMoreData}
                                        hasMore={hasMore}
                                        loader={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Carregando...</b>
                                            </p>
                                        }
                                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Não há mais dados</b>
                                            </p>
                                        }>


                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Serviço</th>
                                                    <th>Aparelho</th>
                                                    <th>Cidade-Uf</th>
                                                    <th>Valor Médio</th>
                                                    <th>Respondidos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'unidade' + i}>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.nome} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.servico} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.aparelho} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.cidade}-{item.uf} </td>
                                                        <td onClick={() => _UnidadeClick(item)}>{Currency(item.valor_medio)}</td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.enviados} </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </InfiniteScroll>            



                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}