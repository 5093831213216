import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import './index.css';


export default function ResumeAssinantes() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


      //#region Effects
      useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
        },[]);


        useEffect(() => {
            if(isLoading){
                window.w_loadObj("#ResumeAssinantes");
            } else {
                window.w_unloadObj("#ResumeAssinantes");
            }
        }, [isLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadResumeAssinantes();
                }
            }, 100);
        }
    }
    //#endregion Init



    //#region Loaders
    const LoadResumeAssinantes = (force = false) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Home/ResumeAssinantes", "POST", {force: force}, user.token, 0*1000))
        .then((data) => {
            setList(data);

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    //#endregion Loaders


        return (

            <div id="ResumeAssinantes" className="col-lg-6">
                <div className="ibox ">
                    <div className="ibox-title">
                        <h5>Assinantes por região</h5>
                    </div>
                    <div className="ibox-content table-responsive">
                        <table className="table table-hover no-margins">
                            <thead>
                            <tr>
                                <th>Qtd</th>
                                <th>Uf</th>
                                <th>Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                                { list.length > 0 ? (
                                    list.map((item, i) => (
                                        <tr key={'it'+i}>
                                            <td>{item.total}</td>
                                            <td>{item.uf}</td>
                                            <td className="text-navy">{Currency(item.valor)} </td>
                                        </tr>
                                    ))
                                ) : (<></>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    )
}