import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import './index.css';

import { Lista } from './Lista'


export default function Orcamentos() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div>
           <Lista />
        </div>
    )
}