import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import './index.css';


export default function ResumeTop() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({ativos: 0, ativos_old: 0, ativos_p: 0, pagantes: 0, pagantes_old: 0, pagantes_p:0, pendente: 0, pendente_old:0, pendente_p:0, receita:0, receita_old:0, receita_p:0});

    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("#ResumeTop");
        } else {
            window.w_unloadObj("#ResumeTop");
        }
    }, [isLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadResumeTop();
                }
            }, 100);
        }
    }
    //#endregion Init



    //#region Loaders
    const LoadResumeTop = (force = false) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Home/ResumeTop", "POST", {force: force}, user.token, 0*1000))
        .then((data) => {
            let _info = Object.assign({}, info);
            _info.ativos = data.ativos;
            _info.ativos_p =  parseInt((data.ativos*100) / (data.ativos+data.ativos_old));
            _info.pagantes = data.pagantes;
            _info.pagantes_p =  parseInt((data.pagantes*100) / (data.pagantes+data.pagantes_old));
            _info.receita = data.receita;
            _info.receita_p =  parseInt((data.receita*100) / (data.receita+data.receita_old));
            _info.pendente = data.pendente;
            _info.pendente_p =  parseInt((data.pendente*100) / (data.pendente+data.pendente_old));
            setInfo(_info);

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    //#endregion Loaders



    return (
        <>
            <div id="ResumeTop" className="row">
                <div className="col-lg-3">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-success float-right">Mensal</span>
                            </div>
                            <h5>Profissionais ativos</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.ativos}</h1>
                            <div className="stat-percent font-bold text-success">{(info.ativos_p)}% <i className={'fa ' + (info.ativos>info.ativos_old?'fa-level-up':'fa-level-down')}></i></div>
                            <small>Últimos 30 dias</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-info float-right">Mensal</span>
                            </div>
                            <h5>Pagantes</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.pagantes}</h1>
                            <div className="stat-percent font-bold text-info">{(info.pagantes_p)}% <i className={'fa ' + (info.pagantes>info.pagantes_old?'fa-level-up':'fa-level-down')}></i></div>
                            <small>Últimos 30 dias</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">Mensal</span>
                            </div>
                            <h5>Receita</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{Currency(info.receita)}</h1>
                            <div className="stat-percent font-bold text-navy">{(info.receita_p)}% <i className={'fa ' + (info.receita>info.receita_old?'fa-level-up':'fa-level-down')}></i></div>
                            <small>Últimos 30 dias</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-danger float-right">Mensal</span>
                            </div>
                            <h5>Pendente</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{Currency(info.pendente)}</h1>
                            <div className="stat-percent font-bold text-danger">{(info.pendente_p)}%  <i className={'fa ' + (info.receita>info.receita_old?'fa-level-up':'fa-level-down')}></i></div>
                            <small>Últimos 30 dias</small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

