import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../../scripts/Request';
import { Context } from '../../../../../Context/AuthContext';
import { Currency } from '../../../../../scripts/StringUtils';
import moment from 'moment/moment';
import './index.css';


export default function Internal() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [lastNotify, setLastNotify] = useState(0);
    const [notification, setNotification] = useState({ title: "", message: "", word:"", loja_id: "", uf_codigo:"", cidade_codigo:"" });
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [names, setNames] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ loja_id: "", nome: "", cpf: "", cidade: "", uf: "" });

    const waitSearchRef = useRef(null);
    const _userRef = useRef();
    const _searchRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
        },[]);


        useEffect(() => {
            if(isLoading){
                window.w_loadObj(".Internal");
            } else {
                window.w_unloadObj(".Internal");
            }
        }, [isLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadEstados();
                }
            }, 100);
        }
    }
    //#endregion Init


    //#region Loaders
    const LoadNotificationCheck = async() => {
        await Promise.resolve(new Request().Run("api/Notifications/Check", "POST", {tipo: 'internal'}, user.token))
        .then((data) => {
            let a = moment(new Date());
            let b = moment(data);
            let c = a.diff(b, 'days');
            //console.log(c);
            setLastNotify(Math.abs(c));
        });
    }


    const LoadEstados = async() => {
        window.w_loadObj(".uf_codigo_i");

        await Promise.resolve(new Request().Run("/json/estados.json", "GET", {}, user.token, 0*3600*1000*24))
            .then((data) => {
                setEstados(data);
                window.w_unloadObj(".uf_codigo_i");
            }).catch(() => {
                window.w_unloadObj(".uf_codigo_i");
            });
        
    }


    const LoadCidades = async(uf_codigo) => {
        if(uf_codigo === "" || uf_codigo === null || uf_codigo === undefined) {
            let _notification = Object.assign({}, notification);
            _notification.uf_codigo = "";
            _notification.cidade_codigo = "";
            _searchRef.current = _notification;
            setNotification(_notification);
            return;
        }
        
        window.w_loadObj(".cidade_codigo_i");

        Promise.resolve(new Request().Run("api/Cadastros/Cidades?uf_codigo="+uf_codigo, "GET", {}, user.token, 0*3600*1000*24))
            .then((data) => {
                if(data) {
                    setCidades(data);           
                }
                window.w_unloadObj(".cidade_codigo_i");
            }).catch(() => {
                window.w_unloadObj(".cidade_codigo_i");
            });
    }
    //#region Loaders



    //#region Handlers
    const _HandlerNChange = (event) => {
        let _notification = Object.assign({}, notification);
        _notification[event.target.id] = event.target.value;
        _searchRef.current = _notification;
        setNotification(_notification);

        if(event.currentTarget.id === "uf_codigo") {
            LoadCidades(event.currentTarget.value); 
        } else if(event.currentTarget.id === "word") {
            let _word = event.target.value;
            if (_word.length > 3) {
                let _names = [...names];
                let _index = _names.findIndex(n => n.nome === event.target.value);
    
                //console.log(_index, _names);
                
    
                if (_index > -1) {
                    _searchRef.current.loja_id = _names[_index].loja_id;
                    setSelectedUser(_names[_index]);

                } else {
                    waitSearchRef.current = setTimeout(() => {
                        setSelectedUser({ loja_id: "", nome: "", cpf: "", cidade: "", uf: "" });
                        _searchRef.current.word = _word;
                        _HandlerSearchTimerChange();
                    }, 700);
                }
            } else {
                _searchRef.current.loja_id = "";
                setSelectedUser({ loja_id: "", nome: "", cpf: "", cidade: "", uf: "" });
            }
        }
    }


    const _HandlerSearchTimerChange = async () => {
        window.w_loadObj(".btnBusca");

        await Promise.resolve(new Request().Run("api/Notifications/Nomes", "POST", _searchRef.current, user.token))
            .then((data) => {
                setNames(data);
                window.w_unloadAll();
            });
    
    }


    const _HandlerSendPushNotifications = async() => {
        let _notification = Object.assign({tipo: 'internal'}, notification);
        _notification.loja_id = _searchRef.current.loja_id;

        //console.log(_notification);
        
        setIsLoading(true);
        await Promise.resolve(new Request().Run("api/Notifications/Send", "POST", _notification, user.token))
        .then((data) => {
            if(data.toString() === "loaded") {
                window.swal("Sucesso", "Notificações enviadas para a fila", "success");
                setNotification({ title: "", message: "" });
                setSelectedUser({ loja_id: "", nome: "", cpf: "", cidade: "", uf: "" });
                setNotification({ title: "", message: "", word:"", loja_id: "", uf_codigo:"", cidade_codigo:"" });
            }
            setIsLoading(false);
            LoadNotificationCheck();
        });
    }
    //#endregion Handlers


    return (<>
        <div className="Internal col-lg-6">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Notificações no App</h5>
                </div>

                <div className="ibox-content">
                    
                    <div>
                        <select id="uf_codigo" name="uf_codigo" onChange={_HandlerNChange} defaultValue={''} value={notification.uf_codigo} className="form-control uf_codigo_i">
                            <option value="">Todos Estados</option>
                            {estados.map((option, i) => (
                                <option key={'opta_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                            ))}
                        </select>

                        <br />
                       
                        <select id="cidade_codigo" name="cidade_codigo" onChange={_HandlerNChange} disabled={notification.uf_codigo===""} defaultValue={''} value={notification.cidade_codigo}  className="form-control cidade_codigo_i">
                            <option value="">Todas Cidades</option>
                            {cidades.map((option, i) => (
                                <option key={'citya_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                            ))}
                        </select>
                      
                        <br />
                    </div>

                    <div>
                        <label>Escolher um profissional <span style={{color:'red'}} className={selectedUser.loja_id===''?'':'hide'}>(Nenhum profissional escolhido)</span></label><br />
                        <input type="text" id="word" list="nameslist1" placeholder="" className="input form-control h35 word_i" value={notification.word} onChange={_HandlerNChange } autoComplete="off"/>
                        <datalist id="nameslist1">
                            {names.map((item, i) => (
                                <option key={'usera' + i} value={item.nome}>{item.cidade}-{item.uf}</option>
                            ))}
                        </datalist>
                    </div>

                </div>

                <div className="ibox-content">
                    
                    <div>
                        <label>Titulo</label><br />
                        <input type="text" id="title" placeholder="" className="input form-control h35" value={notification.title} onChange={_HandlerNChange } />
                    </div>


                    <div>
                        <label>Mensagem</label><br />
                        <textarea id="message" placeholder="" onChange={_HandlerNChange} style={{width:'100%', height:'80px'}} value={notification.message}>{notification.message}</textarea>
                    </div>

                    <button id="btnBusca" type="button" className="btn btn btn-primary h35 btnBusca" onClick={_HandlerSendPushNotifications} disabled={notification.title==='' || notification.message===''}> <i className="fa fa-upload"></i> Enviar Notificações</button>
                </div>
            </div>
        </div>
    </>)
}