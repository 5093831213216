import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import './index.css';
import Push from './Push';
import Internal from './Internal';


export default function Notifications() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            <div className="row">
            <Push />
            <Internal />
        </div>
    </div>
    )
}