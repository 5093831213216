import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "react-bootstrap";
import './index.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import moment from 'moment/moment';



export function Lista() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({word: "", uf_codigo: "", cidade_codigo: "", ativos: ""});
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const searchRef = useRef({});
    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [input, setInput] = useState({});
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);

    useEffect(() => {
        if (user.token !== "") { 
            _userRef.current = user;  
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);

    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(async()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    await LoadEstados();
                }
            }, 100);
        }
    }
    //#endregion Init


    //#region Loaders
    const LoadEstados = async() => {
        window.w_loadObj("#uf_codigo");

        await Promise.resolve(new Request().Run("/json/estados.json", "GET", {}, user.token, 3600*1000*24))
            .then((data) => {
                setEstados(data);
                window.w_unloadObj("#uf_codigo");
            }).catch(() => {
                window.w_unloadObj("#uf_codigo");
            });
        
        _HandlerSearchChange(null);
    }


    const LoadCidades = async(uf_codigo) => {
        window.w_loadObj("#cidade_codigo")

        Promise.resolve(new Request().Run("api/Cadastros/Cidades?uf_codigo="+uf_codigo, "GET", {}, user.token, 3600*1000*24))
            .then((data) => {
                if(data) {
                    setCidades(data); 
                    //searchRef.current.cidade_codigo = data[0].id;
                    setSearch(searchRef.current);              
                }
                window.w_unloadObj("#cidade_codigo");
            }).catch(() => {
                window.w_unloadObj("#cidade_codigo");
            });
    }


    const LoadLoja = async(loja_id) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Cadastros/GetLoja?loja_id="+loja_id, "GET", {}, user.token, 0*3600*1000*24))
            .then((data) => {
                if(data) {
                    //console.log(data);
                    setSelectedUser(data);            
                }
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleInputChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});

        let _search = { ...search};
        _search[e.currentTarget.id] = e.currentTarget.value;
        searchRef.current = _search;
        setSearch(_search);

        if(e.currentTarget.id === "uf_codigo") {
            LoadCidades(e.currentTarget.value); 
        } 
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Cadastros/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", search, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                listRef.current = listRef.current.concat(data.list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Cadastros/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", search, user.token))
                .then((data) => {
                    listRef.current = listRef.current.concat(data.list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UnidadeClick = (item) => {
        LoadLoja(item.loja_id);
        setShowModal(true);
    }


    const _DisableUnidade = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja inativar esse profissional?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Cadastros/Disable", "POST", { loja_id: item.loja_id }, user.token))
                .then((data) => {
                    listRef.current = listRef.current.filter(u => { return u.loja_id !== item.loja_id } )
                    setList(listRef.current);
                    setIsLoading(false);
                }).catch(()=> {
                    setIsLoading(false);
                });
            } else {
               
            }
         });
    }


    const _handleCloseModal = async() => {
        setShowModal(false);
    }


    const _HandleExpiresChange = async(event, loja_id, plano_id) => {
        console.log(moment(event.target.value).format("YYYY-MM-DD"));
        let _selectedUser = Object.assign({}, selectedUser);
        _selectedUser.contrato.data_expires = moment(event.target.value).format("YYYY-MM-DD");
        setSelectedUser(_selectedUser);

        setIsLoading(true);
            Promise.resolve(new Request().Run("api/Cadastros/UpdateExpires", "POST", { loja_id: loja_id, plano_id: plano_id, expires: moment(event.target.value).format("YYYY-MM-DD") }, user.token))
            .then((data) => {
                setIsLoading(false);

                
            }).catch(()=> {
                setIsLoading(false);
            });
    }
    //#endregion Handlers



    return (
        <>
             <div className="row CadastrosLista">
                <div className="col-lg-12">
                    <div className="ibox">



                        <div className="ibox-content">

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="text" id="word" name="word" placeholder="Busca por nome" value={search.word} className="form-control" onChange={_HandleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select id="uf_codigo" name="uf_codigo" onChange={_HandleInputChange} defaultValue={''} value={search.uf_codigo} className="form-control">
                                        <option value="">Todos Estados</option>
                                        {estados.map((option, i) => (
                                            <option key={'opt_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select id="cidade_codigo" name="cidade_codigo" onChange={_HandleInputChange} disabled={search.uf_codigo===""} defaultValue={''} value={search.cidade_codigo}  className="form-control">
                                        <option value="">Todas Cidades</option>
                                        {cidades.map((option, i) => (
                                            <option key={'city_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <select id="ativos" name="ativos" onChange={_HandleInputChange} defaultValue={''} value={search.ativos} className="form-control">
                                        <option value="">Todas assinaturas</option>
                                        <option value="0">Assinatura ativa</option>
                                        <option value="1">Assinatura inativa</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <button className="btn btn-white btn-sm" type="button"  style={{ height:'35px', width:'35px' }} onClick={_HandlerSearchChange}><i className={'fa fa-search'}></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <InfiniteScroll
                                        dataLength={list.length}
                                        next={_fetchMoreData}
                                        hasMore={hasMore}
                                        loader={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Carregando...</b>
                                            </p>
                                        }
                                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Não há mais dados</b>
                                            </p>
                                        }>


                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Whatsapp</th>
                                                    <th>E-mail</th>
                                                    <th>Cidade-Uf</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'unidade' + i} style={{cursor:'pointer'}}>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.nome} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.whatsapp} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.email} </td>
                                                        <td onClick={() => _UnidadeClick(item)}> {item.cidade}-{item.uf} </td>
                                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><label className={'btn btn-sm ' + (item.assinatura===1?'btn-primary':'btn-white')} type="button" style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}>{item.assinatura===1?'Assinante':'Expirado'}</label></td>
                                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className={'btn btn-sm ' + (item.ativo===1?'btn-primary':'btn-white')} type="button" onClick={() => _DisableUnidade(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}>{item.ativo===1?'Ativo':'Inativo'}</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </InfiniteScroll>            



                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{selectedUser !==  null ? (selectedUser.nome) : (<></>)}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                    {selectedUser !==  null ? (<>
                        <div className="row">
                            <div className="col-sm-4">
                                 <label><b>Whatsapp</b></label> <br />
                                 <span>{selectedUser.whatsapp}</span>                
                            </div>
                            <div className="col-sm-4">
                                <label><b>E-mail</b></label> <br />
                                 <span>{selectedUser.email}</span>                     
                            </div>
                            <div className="col-sm-4">
                                <label><b>{selectedUser.pessoa===0?'CPF':'CNPJ'}</b></label> <br />
                                 <span>{selectedUser.cpf}</span>                     
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-4">
                                 <label><b>Cidade</b></label> <br />
                                 <span>{selectedUser.cidade}-{selectedUser.uf}</span>                
                            </div>
                            <div className="col-sm-4">
                                <label><b>Assinatura</b></label> <br />
                                 <span className={selectedUser.assinatura===0?'modal_red':''}>{selectedUser.assinatura===0?'Expirada':'Ativa'}</span>                     
                            </div>
                            <div className="col-sm-4">
                                <label><b>Situação</b></label> <br />
                                 <span>{selectedUser.ativo===0?'Inativo':'Ativo'}</span>                     
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">
                                 <label><b>Plano</b></label> <br />
                                 <span>{selectedUser.contrato.plano}</span>                
                            </div>
                            <div className="col-sm-4">
                                <label><b>Vencimento</b></label> <br />
                                <span><input type="date" className="form-control" value={selectedUser.contrato.data_expires} onChange={(event) => _HandleExpiresChange(event, selectedUser.loja_id, selectedUser.plano_id)}/></span>                 
                            </div>
                            <div className="col-sm-4">
                                <label><b>Aquisição</b></label> <br />
                                <span><input type="date" className="form-control" value={selectedUser.contrato.data_aquisicao} disabled/></span>                       
                            </div>
                        </div>
                        </>) : (<div>Nenhum dado Encontrado</div>)}
                    </MDBModalBody>

                    <MDBModalFooter>
                        <Button variant="secondary" onClick={_handleCloseModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}